import * as actions from '../types';

const initialState = {};

const themeReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actions.UI_GET_THEME: {
      return initialState;
    }
    case actions.UI_SET_THEME: {
      return { ...payload };
    }
    default: {
      return state;
    }
  }
};

export default themeReducer;

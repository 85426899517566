import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import Header from 'components/Header';
import Footer from 'components/Footer';
import MainSlider from 'components/MainSlider';
import TopBar from 'components/TopBar';

const useStyles = makeStyles({
  divider: {
    margin: '24px 9px',
    height: '12px',
    width: '100%',
    backgroundColor: '#999999',
  },
});

const Home = () => {
  const theme = useSelector((state) => state.theme);
  const classes = useStyles();

  return (
    <React.Fragment>
      <TopBar />
      <Header />
      <MainSlider />
      <div className={classes.divider} />
      {theme.htmlTest && (
        <span dangerouslySetInnerHTML={{ __html: theme.htmlTest }} />
      )}
      <div className={classes.divider} />
      {theme.wysiwygTest && (
        <div dangerouslySetInnerHTML={{ __html: theme.wysiwygTest }} />
      )}
      <Footer />
    </React.Fragment>
  );
};

export default Home;

import 'fontsource-montserrat';

const baseTheme = {
  palette: {
    primary: {
      main: '#003049',
      light: '#355975',
      dark: '#000722',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#d62828',
      light: '#ff6152',
      dark: '#9c0000',
      contrastText: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'montserrat',
    fontSize: 12,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#E0E0E0',
        },
        '@font-face': 'montserrat',
      },
    },
  },
};

export default baseTheme;

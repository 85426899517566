import React from 'react';
import { makeStyles } from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

const Footer = () => {
  const classes = useStyles();
  return <div className={classes.root}></div>;
};

export default Footer;

import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';

import styles from './styles';

const useStyles = makeStyles(styles);
const { REACT_APP_HOST } = process.env;

const Header = () => {
  const theme = useSelector((state) => state.theme);
  const classes = useStyles({ theme, MuiTheme: useTheme() });

  if (theme === null) {
    return null;
  }

  return (
    <div className={classes.root}>
      {theme.logo && (
        <img
          src={`${REACT_APP_HOST}${theme.logo.path}`}
          alt='logo'
          className={classes.logo}
        />
      )}
    </div>
  );
};

export default Header;

import 'react-slideshow-image/dist/styles.css';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Fade } from 'react-slideshow-image';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import styles from './styles';

const useStyles = makeStyles(styles);
const { REACT_APP_HOST } = process.env;

const MainSlider = () => {
  const theme = useSelector((state) => state.theme);
  const classes = useStyles({ theme });

  if (!theme.mainSliderGallery) {
    return null;
  }

  return (
    <div className='slide-container'>
      <Fade arrows={false} duration={2800}>
        {theme.mainSliderGallery.map((item) => {
          return (
            <div key={item.path} className='each-fade'>
              <div
                className={classes.slide}
                style={{
                  backgroundImage: `url(${REACT_APP_HOST}${item.path})`,
                }}
              >
                <Typography
                  style={{ color: item.meta.textColour }}
                  variant='h3'
                >
                  {item.meta.title}
                </Typography>
                <Typography
                  style={{ color: item.meta.textColour }}
                  variant='h4'
                >
                  {item.meta.caption}
                </Typography>
              </div>
            </div>
          );
        })}
      </Fade>
    </div>
  );
};

export default MainSlider;

const styles = {
  root: {
    width: '100%',
    backgroundColor: ({ theme }) => theme.topBarColour,
    color: ({ theme }) => theme.secondaryTextColour,
    display: 'flex',
    justifyContent: 'space-around',
    lineHeight: '20px',
    fontWeight: 600,
    padding: '4px 0px',
  },
};

export default styles;

const styles = {
  root: {
    width: '100%',
    backgroundColor: ({ theme }) => theme.headerBarColour,
  },
  logo: {
    height: '18vh',
    margin: ({ MuiTheme }) => MuiTheme.spacing(),
  },
};

export default styles;

const styles = {
  slide: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    height: '80vh',
    width: '100%',
  },
};

export default styles;

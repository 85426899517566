import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';

import styles from './styles';

const useStyles = makeStyles(styles);

const TopBar = () => {
  const theme = useSelector((state) => state.theme);
  const classes = useStyles({ theme });

  if (!theme.topBarInformation) {
    return null;
  }

  return (
    <div className={classes.root}>
      {theme.topBarInformation.map((info) => (
        <Link key={`topBar-${info.text}`} href={info.href} underline='none'>
          {info.text}
        </Link>
      ))}
    </div>
  );
};

export default TopBar;

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getTheme } from 'redux/actions/themeActions';
import Home from 'views/Home';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTheme());
  }, [dispatch]);
  return <Home />;
}

export default App;

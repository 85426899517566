import * as actions from '../types';

const { REACT_APP_CMS_ENDPOINT, REACT_APP_CMS_KEY } = process.env;

export const getTheme = () => async (dispatch) => {
  dispatch({ type: actions.UI_GET_THEME });

  try {
    const response = await fetch(
      `${REACT_APP_CMS_ENDPOINT}/api/collections/get/Main?token=${REACT_APP_CMS_KEY}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status !== 200) {
      throw new Error('Failed to load Theme');
    }

    const body = await response.json();

    dispatch({ type: actions.UI_SET_THEME, payload: body.entries['0'] });
  } catch (error) {
    console.error(error);
  }
};
